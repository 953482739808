import React from "react";
import Layout from "../components/Layout/Layout";
import { ContactForm } from "components";

const Contact = () => {
  return (
    <Layout title="Contact" fullScreen={true}>
      <ContactForm />
    </Layout>
  );
};

export default Contact;
